<template>
    <section>
        <div class="row mx-0 py-3 justify-center">
            <div class="col-8 d-flex px-0">
                <div :class="`w-50 cr-pointer text-center py-1 ${graficaModel.tipo == 1 ? 'active-cap' : 'border-black text-general'}`" style="border-radius:4px 0px 0px 4px;" @click="setTipo(1)">
                    <i class="icon-cliente" />
                    {{$config.cliente}}
                </div>
                <div :class="`w-50 cr-pointer text-center py-1 ${graficaModel.tipo == 2 ? 'active-cap' : 'border-black text-general'}`" style="border-radius:0px 4px 4px 0px;" @click="setTipo(2)">
                    <i class="icon-leechero-cap" />
                    {{$config.vendedor}}
                </div>
            </div>
        </div>
        <div class="row mx-0 my-3 justify-center">
            <div class="col-10">
                <p class="pl-3 text-general f-12">Carpeta de campañas</p>
                <el-select v-model="carpeta" placeholder="Seleccione" class="w-100" size="small" clearable @change="listarCampana()">
                    <el-option
                    v-for="item in carpetas"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-10 mt-3">
                <p class="pl-3 text-general f-12">Campañas</p>
                <el-select v-model="campana" placeholder="Seleccione" class="w-100" size="small">
                    <el-option
                    v-for="item in campanas"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-10 d-flex justify-content-end mt-2">
                <button class="btn-general px-4" @click="mostrarGrafica()">
                    Mostrar
                </button>
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <div class="row mx-0 my-3">
            <div class="col border-right text-center">
                <p class="f-30 text-general f-600">
                    {{ campanas_count }}
                </p>
                <p class="f-14 text-general">
                    Campañas
                </p>
            </div>
            <div class="col border-left text-center">
                <p class="f-30 text-general f-600">
                    {{ lanzamientos_count }}
                </p>
                <p class="f-14 text-general">
                    Lanzamientos
                </p>
            </div>
        </div>
        <div class="row mx-0 my-3">
            <div class="col border-right text-center">
                <p class="f-30 text-general f-600">
                    {{ destinatarios_count }}
                </p>
                <p class="f-14 text-general">
                    Destinatarios
                </p>
            </div>
            <div class="col border-left text-center">
                <p class="f-30 text-general f-600">
                    {{ apetura_count }}%
                </p>
                <p class="f-14 text-general">
                    Apertura <i class="icon-info-circled-alt text-general f-20" />
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import notificaciones from '~/services/publicidad/notificaciones'
import { mapGetters, mapMutations } from 'vuex'
export default {
    data(){
        return {
            filter_active: 1,
            carpetas: [],
            // carpeta: '',
            campanas: [],
            // campana: '',
            campanas_count: 0,
            lanzamientos_count: 0,
            destinatarios_count: 0,
            apetura_count: 0,
        }
    },
    computed: {
        ...mapGetters({
            graficaModel: 'publicidad/notificaciones/graficaModel',
        }),
        carpeta: {
            get(){
                return this.graficaModel.carpeta
            },
            set(carpeta){
                let model = {...this.graficaModel, carpeta}
                this.setGraficaModel(model)
            }
        },
        campana: {
            get(){
                return this.graficaModel.campana
            },
            set(campana){
                let model = {...this.graficaModel, campana}
                this.setGraficaModel(model)
            }
        },
    },
    watch: {
        filter_active(){
            this.carpetas = []
            this.carpeta = ''
            this.campanas = []
            this.campana = ''
            this.listarCarpetas()
        }
    },
    mounted(){
        this.listarCarpetas()
    },
    beforeDestroy(){
        this.limpiarGraficaModel()
    },
    methods: {
        ...mapMutations({
            setGraficaModel: 'publicidad/notificaciones/setGraficaModel',
            limpiarGraficaModel: 'publicidad/notificaciones/limpiarGraficaModel',
            setGraficaEnvios: 'publicidad/notificaciones/setGraficaEnvios',
            setGraficaCampanas: 'publicidad/notificaciones/setGraficaCampanas',
            setGraficaCedis: 'publicidad/notificaciones/setGraficaCedis',
            setLanzamientos: 'publicidad/notificaciones/setLanzamientos',
        }),
        async listarCarpetas(){
            try {
                this.carpetas = []
                this.carpeta = ''
                this.campanas = []
                this.campana = ''
                let params = {
                    tipo: this.graficaModel.tipo
                }
                const { data } = await notificaciones.listarCarpetas(params);
                this.carpetas = data.carpetas
            } catch (error){
                this.error_catch(error)
            }
        },
        async listarCampana(){
            try {
                this.campanas = []
                this.campana = ''
                if(!this.carpeta) return 
                const { data } = await notificaciones.listarCampana(this.carpeta);
                this.campanas = data.campanas
            } catch (error){
                this.error_catch(error)
            }
        },
        async mostrarGrafica(){
            try {
                let params = {
                    carpeta: this.carpeta,
                    campana: this.campana,
                    tipo: this.graficaModel.tipo
                }
                const { data } = await notificaciones.grafica(params);

                this.campanas_count = data.data.campanas_count
                this.lanzamientos_count = data.data.lanzamientos_count
                this.destinatarios_count = data.data.destinatarios_count
                this.apetura_count = data.data.apetura_count
                this.setGraficaEnvios(data.grafica_envios)
                this.setGraficaCampanas(data.grafica_campanas)
                this.setGraficaCedis(data.grafica_cedis)
                this.setLanzamientos(data.lanzamientos)
            } catch (error){
                this.error_catch(error)
            }
        },
        setTipo(tipo){
            this.carpetas = []
            this.campanas = []
            this.limpiarGraficaModel()
            let model = {...this.graficaModel, tipo}
            this.setGraficaModel(model)
            this.listarCarpetas()
        }
    }
}
</script>
<style lang="scss" scoped>
.active-cap{
    background-color: #000000;
    color: #FFFFFF;
}
</style>
